.o-footer {
  margin-block-start: auto;
  padding-block-start: 20rem;
}

.o-footer__newsletter {
  color: var(--color-negative);
  background-color: var(--color-positive);
}

.o-footer__newsletter-container {
  max-width: var(--max-width-text);
  margin-inline: auto;
  padding-block: calc(var(--gap-block) * 2);
  padding-inline: var(--gap-inline);
}

.o-footer__nav {
  line-height: var(--line-height-dense);
  font-size: var(--font-size-small);
  padding-block: 0.8rem;

  > ul {
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
  }
  > ul > li > a {
    display: block;
    padding: 0.8rem;
    text-decoration-color: transparent;

    &:where(:focus-visible, :hover) {
      text-decoration-color: currentcolor;
    }
  }
}

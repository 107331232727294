@keyframes aButtonRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.a-button {
  padding-block: 0.6rem 0.7rem;
  padding-inline: 1.3rem;
  line-height: var(--line-height-dense-2);
  font-size: var(--font-size-small);
  color: var(--color-positive);
  font-weight: var(--font-weight-sans-normal);
  border: 0.15rem solid currentcolor;
  border-radius: 10rem;
  background-color: var(--color-gray-100);
  transition-property: background-color, box-shadow, translate;
  transition-duration: 200ms;

  &.-solid {
    border: 0;
  }
  &.-loading {
    position: relative;
    color: transparent;
    opacity: 1;

    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 2rem * 0.5);
      top: calc(50% - 2rem * 0.5);
      width: 2rem;
      height: 2rem;
      box-sizing: border-box;
      border-radius: calc(2rem * 0.5);
      border: .2rem solid var(--color-positive);
      border-top-color: transparent;
      animation-name: aButtonRotate;
      animation-duration: 1100ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }


  &:where(:not(.-loading)):where(:focus-visible, :hover) {
    box-shadow: 0 .15rem 0 currentcolor;
    background-color: var(--color-negative);
    translate: 0 -.15rem;
  }

  .a-label + & {
    margin-block-start: 1.6rem;
  }
}

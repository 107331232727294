:root {
  /* Grid */
  --gap-inline: 2.4rem;
  --gap-block: 2.4rem;

  /* Sizes */
  --max-width: 144.8rem;
  --max-width-content: 108rem;
  --max-width-text: 69.6rem;

  /* Typography */
  --font-family-sans: Relative Pro, sans-serif;
  --font-family-serif: GT Alpina, serif;

  /**
   * 1rem equals to 10px.
   * clamp: when browser is wider than 800px maximum of size is applied.
   */
  --font-size-xxx-small: 1.1rem;
  --font-size-xx-small:  1.45rem;
  --font-size-x-small:   clamp(1.5rem, calc(16 / 1480 * 100vw), 1.6rem);
  --font-size-small:     clamp(1.7rem, calc(17 / 1480 * 100vw), 1.8rem);
  --font-size-medium:    clamp(2.0rem, calc(20 / 1480 * 100vw), 2.0rem);
  --font-size-large:     clamp(2.2rem, calc(24 / 1480 * 100vw), 2.4rem);
  --font-size-h5:        2.0rem;
  --font-size-h4:        2.25rem;
  --font-size-h3:        clamp(2.4rem, calc(27 / 1480 * 100vw), 2.7rem);
  --font-size-h2:        clamp(3.1rem, calc(32 / 1480 * 100vw), 3.2rem);
  --font-size-h1:        clamp(3.6rem, calc(40 / 1480 * 100vw), 4.0rem);
  --font-size-h0:        clamp(3.8rem, calc(45 / 1480 * 100vw), 4.5rem);
  --font-size:           var(--font-size-medium);

  --font-weight-sans-normal: 500;
  --font-weight-sans-strong: 800;
  --font-weight-serif-normal: 300;
  --font-weight-serif-strong: 500;

  --leter-spacing-h1: -0.003em;

  --line-height-text:    calc(28 / 20);
  --line-height-dense:   calc(23 / 20); /* used for informational text */
  --line-height-dense-2: calc(25 / 20); /* used for longer text */
  --line-height-large:   calc(30 / 24);
  --line-height-h1:      calc(50 / 48);
  --line-height-heading: calc(38 / 38);
  --line-height:         var(--line-height-text);

  /* Colors */
  --color-positive: hsl(0, 0%, 10%);
  --color-negative: hsl(0, 0%, 100%);
  --color-gray-100: hsla(0, 0%, 95%, 1);
  --color-gray-200: hsla(0, 0%, 82%, 1);
  --color-gray-300: hsla(0, 0%, 70%, 1);
  --color-gray-400: hsla(0, 0%, 60%, 1);
  --color-gray-500: hsla(0, 0%, 50%, 1);
  --color-gray-600: hsla(0, 0%, 40%, 1); /* used for gray text */
  --color-gray-700: hsla(0, 0%, 30%, 1);
  --color-gray-800: hsla(0, 0%, 22%, 1);
  --color-gray-900: hsla(0, 0%, 12%, 1);
  --color-gray: var(--color-gray-500);
  @media (prefers-color-scheme: dark) {
    --color-positive: hsl(0, 0%, 90%);
    --color-negative: hsl(0, 0%, 10%);
    --color-gray-100: hsla(0, 0%, 20%, 1);
    --color-gray-200: hsla(0, 0%, 30%, 1);
    --color-gray-300: hsla(0, 0%, 40%, 1);
    --color-gray-400: hsla(0, 0%, 50%, 1);
    --color-gray-500: hsla(0, 0%, 60%, 1);
    --color-gray-600: hsla(0, 0%, 65%, 1);
    --color-gray-700: hsla(0, 0%, 70%, 1);
    --color-gray-800: hsla(0, 0%, 80%, 1);
    --color-gray-900: hsla(0, 0%, 90%, 1);
  }

  --color-red-200: hsla(360, 100%, 80%, 1);
  --color-red-300: hsla(360, 100%, 70%, 1);
  --color-red-400: hsla(360, 100%, 60%, 1);
  --color-red-500: hsla(360, 100%, 50%, 1);
  --color-red-600: hsla(360, 100%, 40%, 1);
  --color-red-700: hsla(360, 100%, 30%, 1);
  --color-red-800: hsla(360, 100%, 20%, 1);
  --color-red: var(--color-red-500);

  /* --color-jan: #A2F9D6;
  --color-feb: #0000FE;
  --color-mar: #7D9D51;
  --color-apr: #FFD3D2;
  --color-may: #CD9CFF;
  --color-jun: #003A19;
  --color-jul: #EDE915;
  --color-aug: #EE382B;
  --color-sep: #927800;
  --color-oct: #FF5C02;
  --color-nov: #01A296;
  --color-dec: #9F0F2E; */

  --color-jan: rgb(35, 175, 180);
  --color-feb: rgb(20, 30, 240);
  --color-mar: rgb(215, 175, 0);
  --color-apr: rgb(165, 120, 255);
  --color-may: rgb(165, 0, 80);
  --color-jun: rgb(130, 185, 0);
  --color-jul: rgb(223, 32, 46);
  --color-aug: rgb(60, 2, 128);
  --color-sep: rgb(145, 140, 0);
  --color-oct: rgb(248, 105, 0);
  --color-nov: rgb(0, 70, 60);
  --color-dec: rgb(110, 10, 0);
  @media (prefers-color-scheme: dark) {
    --color-jan: rgb(35, 175, 180);
    --color-feb: rgb(20, 30, 240);
    --color-mar: rgb(215, 175, 0);
    --color-apr: rgb(165, 120, 255);
    --color-may: rgb(165, 0, 80);
    --color-jun: rgb(130, 185, 0);
    --color-jul: rgb(223, 32, 46);
    --color-aug: rgb(60, 2, 128);
    --color-sep: rgb(145, 140, 0);
    --color-oct: rgb(248, 105, 0);
    --color-nov: rgb(0, 100, 90);
    --color-dec: rgb(130, 40, 0);
  }

  /* Functional Colors */
  --color-danger: var(--color-red);

  /* Shadows */
  --box-shadow: 0 1px 10px -10px rgba(0,0,0,0.1),
    0 1.5px 20px -10px rgba(0,0,0,0.1),
    0 2px 30px -10px rgba(0,0,0,0.1);

  /* Border */
  --border-width: 0.3rem;
  --border-radius: 0.5rem;
  --border-radius-button: 0.6rem;
  --border-radius-small: 0.4rem;

  /* Others */
  --pythagoras-contstant: 1.414213562373095;
}

.o-event {
  margin-block-start: calc(var(--gap-block));

  @media (min-width: 480px) {
    margin-block-start: calc(var(--gap-block) * 2);
  }
}

.o-event__text {
  padding-block-start: calc(var(--gap-block) * 2);
}

.o-event__text-start,
.o-event__text-end {
  max-width: calc(var(--max-width-text) + 1.6rem * 2);
  margin-inline: auto;

  &::before {
    content: '';
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    background-image: linear-gradient(135deg, var(--color-month) 50%, transparent 50%);
  }
}

.o-event__text-start {
  margin-block-end: calc(1.6rem - 1.4rem);
}

.o-event__text-end {
  margin-block-start: calc(1.6rem - 1.4rem);

  &::before {
    margin-inline-start: auto;
    rotate: 180deg;
  }
}

.a-checkbox {
  box-sizing: border-box;
  appearance: none;
  margin-block-start: 0.15rem;
  width: 1.5rem;
  height: 1.5rem;
  border: .15rem solid currentcolor;
  border-radius: .2rem;
  background-color: var(--color-gray-100);
  background-repeat: no-repeat;
  background-position: 1.6rem;
  background-image: url('/assets/images/icon-checkmark--500.svg');
  background-size: auto .8em;

  @media (prefers-color-scheme: dark) {
    background-image: url('/assets/images/icon-checkmark--500-negative.svg');
  }

  &::placeholder {
    color: var(--color-gray-400);
  }

  &:disabled {
    color: var(--color-gray-600);
  }

  &:checked {
    background-position: center;
  }
}

.t-event {
  align-content: start;
  display: flex;
  flex-direction: column;

  &.-month-jan {
    --color-month: var(--color-jan);
  }
  &.-month-feb {
    --color-month: var(--color-feb);
  }
  &.-month-mar {
    --color-month: var(--color-mar);
  }
  &.-month-apr {
    --color-month: var(--color-apr);
  }
  &.-month-may {
    --color-month: var(--color-may);
  }
  &.-month-jun {
    --color-month: var(--color-jun);
  }
  &.-month-jul {
    --color-month: var(--color-jul);
  }
  &.-month-aug {
    --color-month: var(--color-aug);
  }
  &.-month-sep {
    --color-month: var(--color-sep);
  }
  &.-month-oct {
    --color-month: var(--color-oct);
  }
  &.-month-nov {
    --color-month: var(--color-nov);
  }
  &.-month-dec {
    --color-month: var(--color-dec);
  }
}

.o-artists {
  box-sizing: border-box;
  max-width: calc(var(--max-width));
  width: 100%;
  margin-inline: auto;

  > h2 {
    max-width: var(--max-width-text);
    margin-inline: auto;
    padding-inline: calc(var(--gap-inline) * 1.5);
  }

  @media not all and (min-width: 680px) {
    > h2 {
      padding-inline: calc(var(--gap-inline) * 1);
    }
  }
}

.o-artists__alphabet {
  position: sticky;
  top: 0;
  margin-block-start: 1rem;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    inset-block-end: -0.8rem;
    background-color: color-mix(in lch, var(--color-negative) 80%, transparent);
    backdrop-filter: blur(4px);
    mask-image: linear-gradient(to bottom, white 60%, transparent);
    pointer-events: none;
  }

  > div {
    padding-block: .8rem;
    display: flex;
    overflow-x: auto;
  }

  > div > ul {
    max-width: calc(var(--max-width-text) + 0.4rem * 2);
    width: 100%;
    margin-inline: auto;
    position: relative;
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding-inline: calc(var(--gap-inline) * 1.5 - 0.4rem);
  }
  > div > ul > li > :where(a, span) {
    display: block;
    text-transform: uppercase;
    font-size: var(--font-size-small);
    line-height: var(--line-height-dense);
    padding: 0.4rem;
    text-decoration-color: transparent;
  }
  > div > ul > li > span {
    color: var(--color-gray-400);
  }
  > div > ul > li > a {
    &.-active {
      text-decoration-color: currentcolor;
    }
  }

  @media not all and (min-width: 680px) {
    max-width: calc(100vw - var(--gap-inline));

    > div > ul {
      padding-inline: calc(var(--gap-inline) * 1 - 0.4rem);
    }
  }
}

.o-artists__section {
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  margin-inline: auto;
  margin-block-start: calc(var(--gap-block) * 1);
  scroll-margin-block-start: 4.8rem;
  padding-inline: .8rem;

  > dl {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--gap-inline);
    align-items: start;
  }
}

.m-video {
  margin-inline: auto;
  max-width: calc(var(--max-width-text) + 1.6rem * 2);
  width: 100%;

  > div {
    position: relative;
    aspect-ratio: 16 / 9;
    border-radius: 3.2rem;
    overflow: hidden;
    transition-property: border-radius;
    transition-duration: 250ms;

    > * {
      position: absolute;
      inset: 0;
    }

    > iframe {
      inline-size: 100%;
      block-size: 100%;
      opacity: 0;
    }
  }

  &.-animate {
    > div {
      transition-property: border-radius;
      transition-duration: 250ms;
      transition-delay: 100ms;
      > iframe {
        transition-property: opacity;
        transition-duration: inherit;
        transition-delay: inherit;
      }
    }
  }
  &.-active > div {
    border-radius: 0;
  }
  &.-active > div > iframe {
    opacity: 1;
  }
  .m-mediathek-item & {
    height: 100%;
    > div {
      aspect-ratio: auto;
      block-size: 100%;
      border-radius: 0;
      > iframe {
        block-size: 100%;
      }
    }
  }

  * + & {
    margin-block-start: calc(var(--gap-block) * 2);
  }

  :where(h2) + & {
    margin-block-start: calc(var(--gap-block) * 1);
  }

  .o-event__text-container > &:first-child {
    margin-block-start: calc(var(--gap-block));
  }
}

.m-video__cookie-banner {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  font-size: var(--font-size-small);
  line-height: var(--line-height-dense);
  text-align: center;
  text-shadow: 0 0 1px var(--color-negative) 1px;
  background-color: var(--color-gray-100);

  > * {
    position: relative;
  }

  > strong {
    display: block;
  }

  > p {
    margin-block-start: .8rem;
    margin-block-end: 1.4rem;
  }

  > .a-label {
    margin-block-start: .8rem;
  }
}

.m-video__preview {
  position: absolute;
  inset: 0;

  > img {
    width: 100%;
    height: 100%;
    filter: blur(10px) saturate(1) brightness(1.5);
    opacity: .2;
    scale: 1.02;
    object-fit: cover;
  }
}

.m-occupation-table > tbody {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: var(--gap-inline);
  row-gap: calc(var(--gap-block) * 0.25);
}
.m-occupation-table > tbody > tr {
  display: contents;
}
.m-occupation-table > tbody > tr > th {
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-serif-strong);

  > a {
    text-decoration-color: var(--color-gray-200);
    /* text-decoration-thickness: 0.1ex; */
    text-underline-offset: 0.25ex;

    &:focus-visible,
    &:hover {
      text-decoration-color: currentcolor;
    }
  }
}
.m-occupation-table > tbody > tr > td {
  letter-spacing: 0.01em;
}

.m-events-list {
  max-width: var(--max-width);
  margin-block-start: var(--gap-block);
  margin-inline: auto;
  overflow: hidden;

  > ol {
    margin: calc(var(--border-width) * -0.5);
    list-style: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    > li {
      display: grid;
      align-items: stretch;
    }
  }

  @media (min-width: 560px) {
    > ol {
      grid-template-columns: repeat(2, 1fr);

      > li {
        aspect-ratio: 1;
      }
    }
  }
  @media (min-width: 856px) {
    > ol {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 1132px) {
    margin-block-start: 0;

    > ol {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.o-works__container {
  --padding-block: .8rem;
  --padding-inline: 1.2rem;

  max-width: var(--max-width);
  margin-inline: auto;
  margin-block-start: calc(var(--gap-block) * 2);

  > h1 {
    margin-block-end: var(--gap-block);
  }

  & table tr:not(:first-child) > * {
    padding-block-start: var(--padding-block);
  }
  & table tr:not(:last-child) > * {
    padding-block-end: var(--padding-block);
  }
  & table tr > *:not(:first-child) {
    padding-inline-start: var(--padding-inline);
  }
  & table tr > *:not(:last-child) {
    padding-inline-end: var(--padding-inline);
  }
  & table small {
    display: block;
  }
}

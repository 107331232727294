.a-pdf-reader {
  max-width: calc(var(--max-width-text) + 1.6rem * 2);
  width: 100%;
  margin-inline: auto;
  aspect-ratio: 210 / 297;

  * + & {
    margin-block-start: calc(var(--gap-block) * 2);
  }
}

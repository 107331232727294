.o-work__container {
  > h2 {
    max-width: calc(var(--max-width-text));
    margin-block-start: calc(var(--gap-block) * 2);
    margin-inline: auto;
    padding-inline: var(--gap-block);
  }
}


.o-work__header {
  max-width: calc(var(--max-width-text));
  margin-block-start: var(--gap-block);
  margin-inline: auto;
  margin-block-end: calc(var(--gap-block));
  padding-inline: var(--gap-inline);
  font-size: var(--font-size-medium);
  line-height: var(--line-height--dense-2);

  > strong {
    display: block;
    margin-block-end: .2rem;
    line-height: var(--line-height-heading);
    font-size: var(--font-size-h3);
    font-family: var(--font-family-serif);
    font-weight: var(--font-weight-serif-strong);
  }

  > h2 {
    font-size: var(--font-size-h1);
  }

  > p {
    margin-block-start: 0.4rem;
  }
}


.o-work__title {
  font-weight: var(--font-weight-serif-normal);

  & a {
    text-decoration: none;
    > span {
      text-decoration: underline;
      text-decoration-color: var(--color-gray-300);
      text-decoration-thickness: 0.07ex;
      text-underline-offset: 0.22ex;
      transition-property: text-decoration-color;
      transition-duration: 200ms;
    }

    &:focus-visible,
    &:hover {
      > span {
        text-decoration-color: currentcolor;
      }
    }
  }

  > strong {
    font-weight: var(--font-weight-serif-strong);
  }
}

.m-event-thumb {
  display: block;
  margin: calc(var(--border-width) * -0.5);
  /* height: 100%; */
  color: var(--color-month);
  text-decoration: none;
  border: var(--border-width) solid var(--color-month);
  overflow: hidden;
  /* border-inline-start: var(--border-width) solid var(--color-month); */

  > div {
    position: relative;
    overflow: hidden;
    padding-block: calc(var(--gap-block) - var(--border-width) * .5);
    aspect-ratio: 1;

    &::after {
      content: '';
      position: absolute;
      inset-block-end: 0;
      inset-inline: 0;
      height: calc(var(--gap-block) - var(--border-width) * .5);
      background-image: linear-gradient(transparent, var(--color-negative) 75%);
      opacity: 0.9;
    }
  }

  &.-upcoming {
    position: relative;
    max-width: var(--max-width);
    margin-inline: calc(var(--gap-inline) * -1);
    margin-block: 0;
    margin-block-start: calc(var(--gap-inline) * 1.5);
    border-block-start-width: 0;
    border-inline-width: 0;

    > div {
      display: grid;
      padding: 0;
      aspect-ratio: auto;

      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
  }

  &.-month-jan {
    --color-month: var(--color-jan);
  }
  &.-month-feb {
    --color-month: var(--color-feb);
  }
  &.-month-mar {
    --color-month: var(--color-mar);
  }
  &.-month-apr {
    --color-month: var(--color-apr);
  }
  &.-month-may {
    --color-month: var(--color-may);
  }
  &.-month-jun {
    --color-month: var(--color-jun);
  }
  &.-month-jul {
    --color-month: var(--color-jul);
  }
  &.-month-aug {
    --color-month: var(--color-aug);
  }
  &.-month-sep {
    --color-month: var(--color-sep);
  }
  &.-month-oct {
    --color-month: var(--color-oct);
  }
  &.-month-nov {
    --color-month: var(--color-nov);
  }
  &.-month-dec {
    --color-month: var(--color-dec);
  }

  &:focus-visible,
  &:hover {
    > div {
      &::before {
        translate: 0 0;
      }
    }
  }

  @media (min-width: 480px) {
    > div {
      padding-inline: calc(var(--gap-block) - var(--border-width) * .5);

      &::before {
        --border-width: calc(var(--gap-block) - 1.2rem);
        content: '';
        position: absolute;
        z-index: 1;
        inset: 0;
        inset-block-end: calc(var(--border-width) * -1);
        inset-inline-end: calc(var(--border-width) * -1);
        border-inline-start: var(--border-width) solid var(--color-month);
        border-block-start: var(--border-width) solid var(--color-month);
        pointer-events: none;
        translate: calc(var(--pythagoras-contstant) * var(--border-width) * -1) calc(var(--pythagoras-contstant) * var(--border-width) * -1);
        transition-property: translate;
        transition-duration: 400ms;
      }
    }
    &.-upcoming {
      margin-inline: auto;
    }
  }
  @media (max-width: 559px) {
    > div {
      aspect-ratio: auto;
    }
    &.-upcoming {
      > div {
        grid-template-rows: auto auto auto;
      }
    }
  }
  @media (min-width: 560px) {
    &.-upcoming {
      > div {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  @media (min-width: 856px) and (max-width: 1131px) {
    &.-upcoming {
      max-width: calc(66.666% + var(--border-width) * 0.666);

      .o-events li:nth-of-type(odd) & {
        margin-inline-start: 0;
      }
      .o-events li:nth-of-type(even) & {
        margin-inline-end: 0;
      }
    }
  }
  @media (min-width: 1132px) {
    &.-upcoming {
      margin-block-start: 0;
      > div {
        grid-template-columns: repeat(4, 1fr);
        aspect-ratio: 4/1;
      }
    }
  }
}

.m-event-thumb__header {
  margin-block-end: 1.6rem;
  font-size: var(--font-size-small);
  line-height: var(--line-height-dense-2);

  .m-event-thumb.-upcoming & {
    margin-block-end: 0;
    padding: calc(var(--gap-block) - var(--border-width) * .5);
    font-size: var(--font-size-medium);
  }

  @media (max-width: 559px) {
    .m-event-thumb.-upcoming & {
      padding-block: calc(var(--gap-block) * 0.75 - var(--border-width) * .5);
      color: var(--color-negative);
      background-color: var(--color-month);
    }
  }
  @media (min-width: 856px) {
    .m-event-thumb.-upcoming & {
      aspect-ratio: 1;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .m-event-thumb.-upcoming & {
      background-color: var(--color-month);
      transition-property: background-color, color;
      transition-duration: 400ms;

      .m-event-thumb:is(:focus-visible, :hover) & {
        color: var(--color-negative);
        background-color: var(--color-month);
      }
    }

    @media (min-width: 560px) {
      .m-event-thumb.-upcoming & {
        background-color: transparent;
      }
    }
  }
  @media (prefers-reduced-motion: no-preference) {
    .m-event-thumb.-upcoming & {
      background-image: linear-gradient(90deg, var(--color-month) 50%, transparent 50%);
      background-size: 200% 100%;
      background-position: 100%;
      transition-property: background-position, color;
      transition-duration: 400ms;

      .m-event-thumb:is(:focus-visible, :hover) & {
        color: var(--color-negative);
        background-position: 0%;
      }
    }
  }
}

.m-event-thumb__time {
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-serif-normal);

  .m-event-thumb.-upcoming & {
    font-size: var(--font-size-h1);
    line-height: var(--line-height-heading);
    font-weight: var(--font-weight-serif-strong)
  }

  .m-event-thumb:where(.-cancelled, .-postponed) & {
    text-decoration-style: solid;
    text-decoration-line: line-through;
  }
}

.m-event-thumb__location {
  &::before {
    content: '';
    display: inline-block;
    vertical-align: top;
    height: 1em;
    aspect-ratio: 15 / 20;
    mask-image: url('/assets/images/icon-arrow-round-east--400.svg');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: auto 1em;
    background-color: currentcolor;
    translate: 0 0.1em;
  }

  .m-event-thumb.-upcoming & {
    margin-block-start: 1.5rem;
  }
}

.m-event-thumb__title {
  font-family: var(--font-family-serif);
  line-height: var(--line-height-dense);
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-serif-normal);

  > span {
    display: block;
  }
  /* Veranstaltung abgesagt oder verschoben */
  > span > em {
     display: block;
     margin-block-start: -0.02em;
     margin-block-end: 0.8rem;
     font-family: var(--font-family-sans);
     font-weight: var(--font-weight-sans-strong);
  }
  > span > strong {
    display: block;
    font-weight: var(--font-weight-serif-strong);
  }
  > span:nth-child(2) {
    font-size: var(--font-size-medium);
    font-family: var(--font-family-sans);
    font-weight: var(--font-weight-sans-normal);
    line-height: var(--line-height-dense-2);
  }
  > span + span {
    margin-block-start: .6rem;
  }

  .m-event-thumb.-upcoming & {
    box-sizing: border-box;
    /* max-width: 36rem; */
    padding: calc(var(--gap-block) - var(--border-width) * .5);
    font-size: var(--font-size-h1);
    line-height: var(--line-height-heading);

    > span + span {
      margin-block-start: 1.4rem;
    }
  }

  @media (max-width: 559px) {
    .m-event-thumb.-upcoming & {
      padding-block: calc(var(--gap-block) * 0.75 - var(--border-width) * .5);
      border-block-start: var(--border-width) solid var(--color-month);

      > span + span {
        margin-block-start: .8rem;
      }
    }
  }
  @media (min-width: 856px) and (max-width: 1131px) {
    .m-event-thumb.-upcoming & {
      margin-inline-start: calc(var(--border-width) * -1);
      border-inline-start: var(--border-width) solid var(--color-month);
    }
  }
}

.m-event-thumb__figure {
  position: relative;
  align-self: stretch;
  width: 100%;
  aspect-ratio: 4 / 2;
  /* background-image: linear-gradient(hsla(0, 0%, 60%, 0.7), hsla(0, 0%, 60%, 0.7)); */
  background-color: var(--color-month);

  > img {
    width: 100%;
  }

  > figcaption {
    position: absolute;
    inset-block-end: 0;
    inset-inline-end: 0;
    padding: 0.4rem 0.8rem;
    color: var(--color-gray-300);
    font-size: var(--font-size-xxx-small);
    line-height: var(--line-height-dense-2);
    background-color: hsla(0, 0%, 0%, 0.3);
    backdrop-filter: blur(4px);
    mask-image: linear-gradient(transparent 0, black 0.4rem);
    transition-property: color;
    transition-duration: 300ms;

    &:hover {
      color: white;
    }
  }

  @media (min-width: 560px) {
    grid-column-end: span 2;
  }
  @media (min-width: 1132px) {
    .o-events li:nth-of-type(odd) & {
      margin-inline-start: calc(var(--border-width) * -0.5);
      border-inline-start: var(--border-width) solid var(--color-month);
    }
    .o-events li:nth-of-type(even) & {
      order: -1;
      margin-inline-start: calc(var(--border-width) * -0.5);
      border-inline-end: var(--border-width) solid var(--color-month);
    }
  }
}

@keyframes oNewsItemSlideIn {
  from {
    translate: -100%;
  }
  to {
    translate: 0;
  }
}

.o-news-item {
  margin-block-start: calc(var(--gap-block));

  @media (min-width: 480px) {
    margin-block-start: calc(var(--gap-block) * 2);
  }
}

.o-news-item__triangle {
  --size: 2.4rem;
  width: var(--size);
  margin-block-end: calc(var(--size) * -1);
  aspect-ratio: 1;
  background-image: linear-gradient(135deg, var(--color-month) 50%, transparent 50%);

  @media (prefers-reduced-motion: no-preference) {
    animation-name: oNewsItemSlideIn;
    animation-duration: 800ms;
  }

  @media (min-width: 414px) {
    --size: min(12rem, 11vw);
  }
  @media (min-width: 896px) {
    --size: calc(50vw - var(--max-width-text) * 0.5 - 1.6rem);
  }
}

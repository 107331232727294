.o-layout {
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  width: 100%;
  margin-inline: auto;
  margin-block-start: calc(var(--gap-block));

  & + & {
    margin-block-start: calc(var(--gap-block) * 2);
  }

  @media (min-width: 480px) {
    margin-block-start: calc(var(--gap-block) * 2);
  }
  @media (min-width: 560px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--gap-inline);
    align-items: var(--align-items, 'start');
  }
}


.o-layout__column {
  max-width: calc(4rem * var(--span, 12) + var(--gap-inline) * (var(--span, 12) - 1));

  @media (max-width: 559px) {
    & + & {
      margin-block-start: calc(var(--gap-block));
    }
  }
  @media (min-width: 560px) {
    grid-column-end: span var(--span, 12);
  }
}

.o-news {
  margin-block-start: calc(var(--gap-block));

  > ul > li {
    display: block;
  }
  > ul > li + li {
    margin-block-start: calc(var(--gap-block) * 0);
  }

  @media (min-width: 480px) {
    margin-block-start: calc(var(--gap-block) * 2);
  }
}

.a-text {
  > * + * {
    margin-block-start: calc(var(--line-height) * 1em * 0.5);
  }

  &.-small {
    font-size: var(--font-size-small);
  }
  &.-large {
    font-size: var(--font-size-large);
  }

  & + & {
    margin-block-start: calc(var(--font-size) * var(--line-height));
  }
  &.-large + & {
    margin-block-start: var(--gap-block);
  }
}

.o-events {
  padding-inline: var(--gap-inline);
  margin-block-start: calc(var(--gap-block));

  > section > ol {
    list-style: none;
  }

  @media (min-width: 480px) {
    margin-block-start: calc(var(--gap-block) * 2);
  }
}

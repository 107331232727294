.m-performances {
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  margin-inline: auto;
  margin-block-start: calc(var(--gap-block) * 1);
  padding-inline: .8rem;
  list-style: none;
}

.m-performances__item {
  display: grid;
  grid-template-columns: calc(10ch + var(--gap-inline)) 1fr;
  text-decoration: none;
  color: var(--color-month);
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-serif-normal);
  border-block-start: var(--border-width) solid currentcolor;

  > time {
    padding-block: .8rem;
    padding-inline-start: calc(var(--gap-inline));
    font-weight: var(--font-weight-serif-strong);
  }

  > span {
    padding-block: .8rem;
    padding-inline-start: calc(var(--gap-inline) - var(--border-width));
    padding-inline-rem: 0.8rem;
    border-inline-start: var(--border-width) solid currentcolor;
  }

  > span > strong {
    font-weight: var(--font-weight-serif-strong);
    text-decoration: underline;
    text-decoration-color: var(--color-gray-300);
    transition-property: text-decoration-color;
    transition-duration: 200ms;
  }

  &:focus-visible, &:hover {
    > span > strong {
      text-decoration-color: currentcolor;
    }
  }
}

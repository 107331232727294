html {
  font-family: var(--font-family-sans);
  hyphens: auto;
  -webkit-hyphenate-limit-after: 6;
  -webkit-hyphenate-limit-before: 6;
  hyphenate-limit-chars: 12 6 6;
  color: var(--color-positive);
  background-color: var(--color-negative);
}
body {
  line-height: var(--line-height);
  font-size: var(--font-size);
  font-weight: var(--font-weight-sans-normal);
  overflow-x: hidden;

  * {
    scroll-margin-block-start: 2.4rem;
  }

  @media (max-width: 1111px) {
    &:has(> .o-nav.-open) {
      overflow: hidden;
    }
  }
}

em, i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-serif);
  line-height: var(--line-height-heading);
  font-weight: var(--font-weight-serif-strong);

  & strong {
    font-weight: var(--font-weight-serif-strong);
  }
}

h1 {
  font-size: var(--font-size-h1);

  & sup {
    font-size: 1.8rem;
  }
}
h2 {
  font-size: var(--font-size-h2);
}
h3 {
  font-size: var(--font-size-h3);
}
h4 {
  font-size: var(--font-size-h4);
}
h5 {
  text-transform: uppercase;
  font-size: var(--font-size-h5);
}

a {
  text-decoration-color: var(--color-gray-300);
  transition-property: text-decoration-color;
  transition-duration: 200ms;

  &:focus-visible,
  &:hover {
    text-decoration-color: currentcolor;
  }
}

strong {
  font-weight: var(--font-weight-sans-strong);
}

p + p {
  margin-block-start: calc(var(--line-height) * 1em * 0.5);
}

@font-face {
  font-family: 'Relative Pro';
  src: url('/assets/fonts/relative-medium-pro.woff2') format('woff2'),
      url('/assets/fonts/relative-medium-pro.woff') format('woff'),
      url('/assets/fonts/relative-medium-pro.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Relative Pro';
  src: url('/assets/fonts/relative-black-pro.woff2') format('woff2'),
      url('/assets/fonts/relative-black-pro.woff') format('woff'),
      url('/assets/fonts/relative-black-pro.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

.a-line {
  margin-block: calc(var(--gap-block) * 3);

  > hr {
    content: '';
    display: block;
    margin-inline: auto;
    height: var(--border-width);
    width: calc(var(--gap-inline) * 2.5);
    background-color: var(--color-month);
    rotate: -45deg;
  }
}

.a-input {
  margin-block-start: .8rem;
  padding: 0.4rem 0.8rem;
  font-size: var(--font-size-medium);
  border: .1rem solid currentcolor;
  background-color: var(--color-gray-800);

  &.-newsletter {
    width: 100%;
    max-width: 30ch;
    margin-block-start: 0;
  }
}

@keyframes oNavSlideIn {
  from {
    opacity: 0;
    translate: 0 -0.8rem;
  }
}
@keyframes oNavFadeIn {
  from {
    opacity: 0;
    scale: .98;
  }
}

.o-nav {
  --padding: 0.8rem;
  box-sizing: border-box;

  /* hamburger navigation */
  @media (max-width: 1111px) {
    position: relative;
    z-index: 100;
    height: 10.2rem;
  }
  @media (min-width: 414px) and (max-width: 1111px) {
    height: 11.2rem;
  }
}

.o-nav__container {
  display: grid;
  max-width: calc(var(--max-width) - var(--gap-block) * 2);
  margin-inline: auto;
  padding-inline: var(--gap-inline);
  padding-block-start: calc(var(--gap-block) - var(--padding));
  grid-template-columns: auto auto;
  grid-template-rows: 1fr auto;
  column-gap: calc(var(--gap-inline) * 2);
  justify-content: space-between;
  align-items: start;
  row-gap: 0.8rem;
  background-color: var(--color-negative);

  @media (min-width: 480px) {
    padding-inline: calc(var(--gap-inline) * 2);
  }

  /* hamburger navigation */
  @media (max-width: 1111px) {
    position: absolute;
    z-index: 101;
    inset-inline: 0;
    inset-block-start: 0;
    box-sizing: border-box;
    height: 100%;
    transition-property: height;
    transition-duration: 400ms;

    .o-nav.-open & {
      height: 100vh;
      height: 100dvb;
    }
  }

  /* no hamburger navigation */
  @media (min-width: 1112px) {
    padding-inline: var(--gap-inline);
  }

  @media (min-width: 1132px) {
    padding-inline: calc(var(--gap-inline) * 2);
  }
}

.o-nav__logo {
  margin-inline: calc(var(--padding) * -1);
  padding: var(--padding);

  > picture > img {
    margin-inline-start: -0.4rem;
    height: 7rem;
    width: auto;
  }

  @media (min-width: 414px) {
    > picture > img {
      height: 8rem;
    }
  }
}

.o-nav__main {
  grid-row-end: span 2;
  justify-content: start;
  align-items: end;
  display: grid;
  justify-items: start;

  > ul {
    display: none;
    gap: var(--gap-inline);
    margin-inline: calc(var(--padding) * -1);
    padding-block-end: 0.3rem;

    > li > a {
      display: block;
      padding: var(--padding);
      text-decoration-color: transparent;

      &:where(:focus-visible, :hover) {
        text-decoration-color: currentcolor;
      }

      &.-open {
        text-decoration-color: currentcolor;
      }
    }

  }

  /* hamburger navigation */
  @media (max-width: 1111px) {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: -1;
    grid-template-columns: 1fr;

    > ul {
      padding-block-start: 1.6rem;
      font-size: var(--font-size-h3);
      > li {
        translate: 0 -0.8rem;
        animation-name: oNavSlideIn;
        animation-fill-mode: backwards;
        animation-duration: 200ms;
        animation-timing-function: ease-out;
        animation-delay: calc(6 * 50ms);
      }
      > li:nth-of-type(1) {
        animation-delay: calc(1 * 50ms);
      }
      > li:nth-of-type(2) {
        animation-delay: calc(2 * 50ms);
      }
      > li:nth-of-type(3) {
        animation-delay: calc(3 * 50ms);
      }
      > li:nth-of-type(4) {
        animation-delay: calc(4 * 50ms);
      }
      > li:nth-of-type(5) {
        animation-delay: calc(5 * 50ms);
      }
    }

    .o-nav.-open & > ul {
      display: block;
      > li {
        translate: 0;
      }
    }
  }

  /* no hamburger navigation */
  @media (min-width: 1112px) {
    column-gap: calc(var(--gap-inline) * 2);
    grid-template-columns: auto auto;

    > ul {
      display: flex;
    }
  }
  @media (min-width: 1280px) {
    column-gap: calc(var(--gap-inline) * 4);
  }
}

.o-nav__social-media,
.o-nav__lang-switch {
  --size: 3.4rem;
  grid-column-start: 2;
  display: none;
  gap: 0.8rem;
  align-self: end;
  justify-self: end;

  > a {
    display: grid;
    width: var(--size);
    height: var(--size);
    justify-content: center;
    align-items: center;
    line-height: 1;
    text-transform: uppercase;
    font-size: var(--font-size-small);
    color: var(--color-negative);
    border-radius: 50%;
    text-decoration: none;
    background-color: var(--color-positive);
    transition-property: color, background-color;
    transition-duration: 100ms;

    > svg {
      height: var(--size);
      width: var(--size);

      * {
        fill: currentcolor;
      }
    }

    &:is(:focus-visible, :hover) {
      background-color: var(--color-gray-800);
    }

    &.-inactive {
      box-sizing: border-box;
      color: var(--color-positive);
      border: 2px solid var(--color-positive);
      background-color: transparent;

      &:is(:focus-visible, :hover) {
        background-color: var(--color-gray-100);
      }
    }
  }

  /* hamburger navigation */
  @media (max-width: 1111px) {
    > a {
      animation-name: oNavFadeIn;
      animation-duration: 200ms;
      animation-fill-mode: backwards;
      animation-timing-function: ease-out;
      animation-delay: calc((1 + 6) * 50ms);
    }

    .o-nav.-open & {
      display: flex;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

  @media (min-width: 1112px) {
    display: flex;
  }

}

.o-nav__social-media {
  @media (max-width: 1111px) {
    > a:nth-of-type(1) {
      animation-delay: calc((1 + 6) * 50ms);
    }
    > a:nth-of-type(2) {
      animation-delay: calc((2 + 6) * 50ms);
    }
    > a:nth-of-type(3) {
      animation-delay: calc((3 + 6) * 50ms);
    }
  }
}

.o-nav__lang-switch {
  margin-block-end: 0.7rem;

  @media (max-width: 1111px) {
    > a:nth-of-type(1) {
      animation-delay: calc((1 + 9) * 50ms);
    }
    > a:nth-of-type(2) {
      animation-delay: calc((2 + 9) * 50ms);
    }
  }
}

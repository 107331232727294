.m-text {
  box-sizing: border-box;
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  width: 100%;
  margin-inline: auto;
  padding-inline: var(--gap-inline);

  .m-set & {
    padding-inline: 0;
  }

  > * + * {
    margin-block-start: calc(var(--line-height) * 1em * 0.5);
  }

  * + & {
    margin-block-start: calc(var(--gap-block) * 1);
  }
  :where(.m-video, .m-figure, .m-gallery, .m-cite) + & {
    margin-block-start: calc(var(--gap-block) * 2);
  }
}

@keyframes oMediathekItemAppear {
  from {
    clip-path: circle(0% at bottom left);
  }
  to {
    clip-path: circle(200% at bottom left);
  }
}

.o-mediathek-item {
  --size: 2.4rem;
  margin-block-start: calc(var(--gap-block) * 2);
  min-height: calc(var(--size) + var(--border-width) - 20rem); /* avoid collision, 20rem is padding of footer */

  @media (min-width: 414px) {
    --size: min(12rem, 11vw);
  }
  @media (min-width: 896px) {
    --size: calc(50vw - var(--max-width-text) * 0.5 - 1.6rem);
  }
}

.o-mediathek-item__symbol {
  width: var(--size);
  margin-block-end: calc(var(--size) * -1 - var(--border-width));
  aspect-ratio: 1;
  background-color: var(--color-month);

  > div {
    height: 100%;
    border-block-start: var(--border-width) solid var(--color-month);
    clip-path: circle(100% at bottom right);
    background-color: var(--color-negative);
  }

  @media (prefers-reduced-motion: no-preference) {
    animation-name: oMediathekItemAppear;
    animation-duration: 1600ms;
    animation-timing-function: ease-in;
  }
}

.m-gallery {
  position: relative;
  display: grid;
  grid-template-columns: 0 1fr 0;
  row-gap: 1.6rem;

  * + & {
    margin-block-start: calc(var(--gap-block) * 2);
  }

  @media (min-width: 872px) {
    grid-template-columns: 3.2rem 1fr 3.2rem;
    column-gap: .8rem;
    margin-inline: calc((2.4rem + 1.6rem) * -1);
  }
}


.m-gallery__scroll-container {
  grid-column-start: 2;
  overflow: hidden;
  transition-duration: 200ms;
  transition-property: height;

  > ul {
    position: relative;
    margin-block-end: -4rem;
    padding-block-end: 4rem;
    list-style: none;
    display: grid;
    align-items: start;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    cursor: grab;

    &.-dragging {
      cursor: grabbing;
    }
    &.-no-scroll-snap {
      scroll-snap-type: none;
    }

    > li {
      scroll-snap-align: center;
    }
  }
}


.m-gallery [data-action="show next"],
.m-gallery [data-action="show previous"] {
  width: 4.8rem;
  grid-row-start: 1;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: auto 6.4rem;
  background-color: var(--color-gray-100);
  transition-duration: 200ms;
  transition-property: background-color;

  &[disabled] {
    opacity: 0.4;
  }

  @media (min-width: 872px) {
    background-color: var(--color-gray-600);
  }
}
.m-gallery [data-action="show next"] {
  justify-self: end;
  grid-column-start: 3;
  mask-image: url('/assets/images/icon-arrow-east--alt-400.svg');
  margin-inline-end: .8rem;
  @media (min-width: 872px) {
    margin-inline-end: 0;
  }
}
.m-gallery [data-action="show previous"] {
  grid-column-start: 1;
  mask-image: url('/assets/images/icon-arrow-west--alt-400.svg');
  margin-inline-start: .8rem;
  @media (min-width: 872px) {
    margin-inline-start: 0;
  }
}


.m-gallery__dot-nav {
  grid-column: 1 / -1;

  > ul {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  > ul > li {

  }
  & button {
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 2.8rem;
    height: 2.8rem;

    &::after {
      content: '';
      box-sizing: border-box;
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      border: .1rem solid var(--color-gray-300);
      background-color: var(--color-gray-200);
      transition-duration: 100ms;
      transition-property: border-color, background-color;
    }

    &:where(:focus-visible, :hover) {
      &::after {
        border-color: var(--color-gray-400);
        background-color: var(--color-gray-300);
      }
    }

    &[aria-pressed="true"] {
      &::after {
        border-color: var(--color-gray-600);
        background-color: var(--color-gray-600);
      }
    }
  }
}

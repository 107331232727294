.m-person {
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-serif-normal);

  & strong {
    font-weight: var(--font-weight-serif-strong);
  }

  > dt {
    grid-column-end: span 2;
    font-weight: var(--font-weight-serif-strong);
    font-size: var(--font-size-large);
  }
}
ul.m-person {
  list-style: none;
}

.m-person__title,
.m-person__work {
  display: grid;
  grid-template-columns: 1fr 12rem;

  @media (min-width: 480px) {
    grid-template-columns: 1fr 18rem;
  }
}
a.m-person__title {
  text-decoration: none;
}

.m-person__name,
.m-person__live-data {
  padding-inline-start: calc(1.6rem - var(--border-width));
  border-inline-start: var(--border-width) solid currentcolor;
  padding-block: 0.6rem;
  line-height: var(--line-height-dense);

  @media (min-width: 480px) {
    padding-inline-start: calc(var(--gap-inline) - var(--border-width));
  }
}
.m-person__name {
  border-block-start: var(--border-width) solid currentcolor;

  > small {
    text-decoration: none;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-serif-normal);
  }
}
a.m-person__title .m-person__name > span {
  text-decoration: underline;
  text-decoration-color: var(--color-gray-300);
  transition-property: text-decoration-color;
  transition-duration: 200ms;
}
a.m-person__title:where(:focus-visible, :hover) .m-person__name > span {
  text-decoration-color: currentcolor;
}

.m-person__live-data {
  padding-block-start: calc(0.6rem + var(--border-width));
}

.m-person__work {
  text-decoration: none;
  line-height: var(--line-height-dense-2);

  > strong {
    display: block;
  }

}
a.m-person__work {
  > strong {
    text-decoration: underline;
    text-decoration-color: var(--color-gray-300);
    transition-property: text-decoration-color;
    transition-duration: 200ms;
  }
  &:focus-visible, &:hover {
    > strong {
      text-decoration-color: currentcolor;
    }
  }
}

.m-person__work-title,
.m-person__work-instrumentation {
  padding-block: .8rem;
  padding-inline-start: calc(1.6rem - var(--border-width));
  padding-inline-end: 1.6rem;
  border-inline-start: var(--border-width) solid currentcolor;

  @media (min-width: 480px) {
    padding-inline-start: calc(var(--gap-inline) - var(--border-width));
  }
}
.m-person__work-title,
.m-person__work-year {
  border-block-start: var(--border-width) solid currentcolor;
}

.m-person__work-instrumentation {
  margin-block-start: calc(.8rem * -2);
}

.m-person__work-year {
  align-self: stretch;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  padding-block: .8rem;
  padding-inline-start: calc(1.6rem - var(--border-width));
  padding-inline-end: .8rem;
  border-inline-start: var(--border-width) solid currentcolor;
  font-weight: var(--font-weight-serif-strong);

  @media (min-width: 480px) {
    padding-inline-start: calc(var(--gap-inline) - var(--border-width));
  }
}

@font-face {
  font-family: 'GT Alpina';
  src: url('/assets/fonts/GT-Alpina-Standard-Light.woff2') format('woff2'),
      url('/assets/fonts/GT-Alpina-Standard-Light.woff') format('woff'),
      url('/assets/fonts/GT-Alpina-Standard-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GT Alpina';
  src: url('/assets/fonts/GT-Alpina-Standard-Medium.woff2') format('woff2'),
      url('/assets/fonts/GT-Alpina-Standard-Medium.woff') format('woff'),
      url('/assets/fonts/GT-Alpina-Standard-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.m-mediathek-item {
  max-width: 51.2rem;
  margin-inline: auto;
  background-color: var(--color-month);

  &.-month-jan {
    --color-month: var(--color-jan);
  }
  &.-month-feb {
    --color-month: var(--color-feb);
  }
  &.-month-mar {
    --color-month: var(--color-mar);
  }
  &.-month-apr {
    --color-month: var(--color-apr);
  }
  &.-month-may {
    --color-month: var(--color-may);
  }
  &.-month-jun {
    --color-month: var(--color-jun);
  }
  &.-month-jul {
    --color-month: var(--color-jul);
  }
  &.-month-aug {
    --color-month: var(--color-aug);
  }
  &.-month-sep {
    --color-month: var(--color-sep);
  }
  &.-month-oct {
    --color-month: var(--color-oct);
  }
  &.-month-nov {
    --color-month: var(--color-nov);
  }
  &.-month-dec {
    --color-month: var(--color-dec);
  }

  &.-video {
    border-block-start: var(--border-width) solid var(--color-month);
  }

  @media (min-width: 856px) {
    max-width: none;
    margin-inline: 0;

    &.-video {
      grid-column-start: 1;
      grid-column-end: -1;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      aspect-ratio: 3 / 1;
      max-aspect-ratio: 3 / 1;
      gap: var(--border-width);
      border: none;
    }
  }
}

.m-mediathek-item__title {
  display: grid;
  align-items: end;
  clip-path: circle(calc(58% - 3px));
  background-color: var(--color-negative);

  .m-mediathek-item.-video & {
    aspect-ratio: 16 / 10;
    clip-path: circle(calc(58%) at 50% min(48vw, 25.6rem));
  }

  @media (min-width: 856px) {
    height: 100%;
    align-content: end;
    clip-path: circle(calc(50% - 3px));

    .m-mediathek-item.-video & {
      aspect-ratio: 1;
      clip-path: circle(calc(100% - 3px) at bottom right);
    }
    .m-mediathek-item.-video:nth-of-type(2n - 1) & {
      margin-inline-end: calc(var(--border-width) * -1);
    }
    .m-mediathek-item.-video:nth-of-type(2n) & {
      order: 2;
      margin-inline-start: calc(var(--border-width) * -1);
      clip-path: circle(calc(100% - 3px) at bottom left);
    }
  }

  @media (min-width: 1112px) {
    aspect-ratio: 1;
  }
}

.m-mediathek-item__title-illustration {
  padding-block-end: 1.6rem;
  padding-inline: var(--gap-inline);

  > img,
  > svg {
    margin-inline: auto;
    height: 10rem;
    width: auto;
  }
  > svg {
    & path {
      fill: currentcolor;
    }
  }

  @media (min-width: 856px) {
    > img,
    > svg {
      height: 12rem;
    }

    .m-mediathek-item.-video:nth-of-type(2n - 1) & {
      > img,
      > svg {
        margin-inline-end: 0;
      }
    }
    .m-mediathek-item.-video:nth-of-type(2n) & {
      > img,
      > svg {
        margin-inline-start: 0;
      }
    }
  }
}

.m-mediathek-item__title-text {
  --padding: min(calc(40 / 375 * 100vw), 4.8rem);
  padding-inline: var(--padding);
  padding-block-end: var(--padding);

  > h2 {
    font-family: var(--font-family-serif);
    line-height: var(--line-height-heading);
    font-size: var(--font-size-h3);
  }

  > .a-button-text {
    margin-block-start: 0.8rem;
    line-height: var(--line-height-dense);
    font-size: var(--font-size-small);
  }

  .m-mediathek-item.-video & {
    padding-block-end: calc(var(--padding) * 0.5);
    /* padding-inline: 2.4rem; */
  }

  @media (min-width: 856px) {
    padding-inline: 4.8rem;
    padding-block-end: 5.6rem;

    .m-mediathek-item.-video & {
      padding-block-end: 1.6rem;
    }
    .m-mediathek-item.-video:nth-of-type(2n - 1) & {
      padding-inline-end: var(--gap-inline);
    }
    .m-mediathek-item.-video:nth-of-type(2n) & {
      padding-inline-start: var(--gap-inline);
    }
  }
  @media (min-width: 1112px) {
    padding-inline: 5.6rem;
    padding-block-end: 6.4rem;

    .m-mediathek-item.-video & {
      padding-block-end: 3.2rem;
    }
  }
}

.m-mediathek-item__player {
  grid-column-end: span 2;
  aspect-ratio: 16 / 9;
  border-inline: var(--border-width) solid var(--color-month);
  border-block-end: var(--border-width) solid var(--color-month);


  @media (min-width: 856px) {
    aspect-ratio: auto;
    border: none;
    border-block-start: var(--border-width) solid var(--color-month);

    .m-mediathek-item.-video:nth-of-type(2n - 1) & {
      margin-inline-start: calc(var(--border-width) * -1);
    }
    .m-mediathek-item.-video:nth-of-type(2n) & {
      margin-inline-end: calc(var(--border-width) * -1);
    }
  }
}

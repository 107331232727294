.o-artists-container {
  --padding-mask-image: calc(var(--gap-inline) * 3);
  max-width: calc(var(--max-width-text) + var(--gap-inline) * (2 + 4 + 1));
  /* overflow: hidden; */
  margin-inline: auto;
  margin-block-start: calc(var(--gap-block) * 2);
  scroll-snap-type: x mandatory;
}


.o-artists-container__scroll-container {
  position: relative;
  mask-image: linear-gradient(90deg, transparent, black var(--padding-mask-image), black calc(100% - var(--padding-mask-image)), transparent);

  > button {
    position: absolute;
    z-index: 1;
    inset-block: 0;
    width: calc(var(--gap-inline) * 2);

    &[disabled] {
      cursor: default;
    }

    &:fist-of-type {
      inset-inline-start: 0;
    }
    &:last-of-type {
      inset-inline-end: 0;
    }
  }

  @media not all and (min-width: 680px) {
    mask-image: none;

    > button {
      width: calc(var(--gap-inline) * 1);
    }
  }
}


.o-artists-container__content {
  display: grid;
  grid-auto-columns: minmax(100%, 1fr);
  grid-auto-flow: column;
  padding-inline: calc(var(--gap-inline) * 2);
  align-items: start;
  transition-duration: 400ms;
  transition-property: height;

  > div {
    transition-duration: 400ms;
    transition-property: translate;
  }

  > div[inert] {
    overflow: hidden;
  }

  @media not all and (min-width: 680px) {
    padding-inline: 0;
  }
}


.o-artists-container__nav {
  display: flex;
  box-sizing: border-box;
  margin-block-end: 1.6rem;
  max-width: min(100vw, calc(var(--max-width-text) + var(--gap-inline) * 4));
  margin-inline: auto;
  font-size: var(--font-size-small);
  line-height: var(--line-height-dense-2);
  overflow: auto;

  > div {
    display: grid;
    flex-basis: 100%;
    grid-template-columns: repeat(3, minmax(20rem, 1fr));
    padding-inline: calc(var(--gap-inline) + var(--border-width) * 0.5);
    padding-block-end: 1.6rem;
  }

  > div > button {
    margin-inline: calc(var(--border-width) * -0.5);
    padding-block: .6rem;
    padding-inline: calc(var(--gap-inline) + var(--border-width) * -1);
    border: var(--border-width) solid var(--color-positive);
    text-align: center;
    transition-duration: 100ms;
    transition-property: background-color;

    &[aria-pressed="true"] {
      color: var(--color-negative);
      background-color: var(--color-positive);
    }

    &:not([aria-pressed="true"]):is(:focus-visible, :hover) {
      background-color: var(--color-gray-100);
    }
  }

  @media not all and (min-width: 680px) {
    margin-inline: 0;
    > div {
      padding-inline: calc(var(--gap-inline) * 1);
    }
  }
}

.a-button-hamburger {
  position: relative;
  z-index: 1;
  display: grid;
  order: 1;
  grid-column-start: 2;
  grid-row-start: 1;
  margin-block-start: 4.4rem;
  margin-inline: -1.6rem;
  padding-block: 0.7rem;
  padding-inline: 1.6rem;
  grid-template-columns: auto auto;
  column-gap: .8rem;
  line-height: var(--line-height-dense-2);
  align-items: center;

  > span {
    display: block;
    height: .2rem;
    width: 1.6rem;
    grid-column-start: 1;
    grid-row-start: 1;
    background-color: currentcolor;
    transition-duration: 200ms;
    transition-delay: 200ms, 200ms, 200ms, 0ms;
    transition-property: translate, opacity, scale, rotate;
  }
  > span:nth-of-type(1) {
    translate: 0 -.5rem;
  }
  > span:nth-of-type(3) {
    translate: 0 .5rem;
  }

  .o-nav.-open & {
    > span {
      transition-delay: 0ms, 0ms, 0ms, 200ms;
    }
    > span:nth-of-type(1) {
      translate: 0;
      rotate: 45deg;
    }
    > span:nth-of-type(2) {
      opacity: 0;
      scale: 0 1;
    }
    > span:nth-of-type(3) {
      translate: 0;
      rotate: -45deg;
    }
  }

  @media (min-width: 414px) {
    margin-block-start: 5.3rem;
  }
  @media (min-width: 1112px) {
    display: none;
  }
}

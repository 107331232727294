.m-program-overview {
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  margin-block-start: calc(var(--gap-block) * 2);
  margin-inline: auto;
}

.m-program-overview__container {
  /* max-width: 57rem; */
  padding-inline: var(--gap-inline);

  &::before,
  &::after {
    content: '';
    display: block;
    width: 1.1rem;
    height: 1.1rem;
  }
  &::before {
    margin-inline-start: -1.6rem;
    border-block-start: var(--border-width) solid var(--color-month);
    border-inline-start: var(--border-width) solid var(--color-month);
  }
  &::after {
    margin-inline-start: auto;
    margin-inline-end: -1.6rem;
    border-block-end: var(--border-width) solid var(--color-month);
    border-inline-end: var(--border-width) solid var(--color-month);
  }

  > h2 {
    margin-block-end: calc(var(--gap-block) * 0.5);
    font-size: var(--font-size-xx-small);
    font-family: var(--font-family-sans);
    font-weight: var(--font-weight-sans-normal);
    color: var(--color-month);
  }

  > ol {
    margin-block: calc(var(--gap-block) * -0.25);
    list-style: none;
    font-family: var(--font-family-serif);
    font-weight: var(--font-weight-serif-strong);
    line-height: var(--line-height-dense);

    > li > a {
      display: grid;
      grid-template-columns: calc(var(--gap-inline) * 7) 1fr;
      gap: calc(var(--gap-inline));
      text-decoration: none;
      padding-block: calc(var(--gap-block) * 0.25);
      transition-property: opacity;
      transition-duration: 400ms;
    }

    &:has(a:focus-visible, a:hover) > li > a:not(:focus-visible, :hover) {
      opacity: 0.5;
    }
  }

  > p {
    line-height: var(--line-height-dense-2);
    font-size: var(--font-size-small);
    color: var(--color-month);
  }
  > ol + p {
    margin-block-start: calc(var(--gap-block) * 0.5);
  }
  > p + p {
    margin-block-start: 0.2rem;
  }
}

.m-program-overview__persons {
  > sup {
    vertical-align: top;
    font-size: 1.3rem;
  }
}

.m-program-overview__work-title {
  > strong {
    font-weight: var(--font-weight-serif-strong);
  }

  > sup {
    vertical-align: top;
    font-size: 1.3rem;
    white-space: nowrap;

    > em {
      color: var(--color-month);
    }
  }
}

.m-program-overview__work-subtitle {
  font-family: var(--font-family-sans);
  font-weight: var(--font-weight-sans-normal);
  font-size: var(--font-size-x-small);
}

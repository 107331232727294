.m-form-newsletter {
  min-height: 11.4rem;
  margin-block-start: var(--gap-block);
}

.m-form-newsletter__error {
  font-size: var(--font-size-small);
  line-height: var(--line-height-dense);
  margin-block-start: 1.0rem;
  font-weight: 600;
  color: var(--color-danger);

  &:empty {
    margin-block-start: 0;
  }
}

.m-form-newsletter__success {
  display: flex;
}

.o-mediathek {
  margin-block-start: calc(var(--gap-block));

  > ul {
    margin-inline: auto;
    max-width: 108rem;
    width: 100%;
    display: grid;
    list-style: none;
    gap: var(--border-width);
  }

  @media (min-width: 480px) {
    padding-inline: var(--gap-inline);
    margin-block-start: calc(var(--gap-block) * 2);
  }
  @media (min-width: 560px) {
    padding-inline: calc(var(--gap-inline) * 2);
  }
  @media (min-width: 856px) {
    padding-inline: var(--gap-inline);

    > ul {
      grid-auto-flow: dense;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

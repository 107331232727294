.m-set {
  max-width: var(--max-width-text);
  margin-inline: auto;
  padding-inline: var(--gap-inline);

  *:not(.m-set) + & {
    margin-block-start: calc(var(--gap-block) * 3);
    scroll-margin-block-start: calc(var(--gap-block) * -3 + 2.4rem);

    &::before {
      content: '';
      display: block;
      margin-inline: auto;
      margin-block-end: calc(var(--gap-block) * 3);
      height: var(--border-width);
      width: calc(var(--gap-inline) * 2.5);
      background-color: var(--color-month);
      rotate: -45deg;
    }
  }
  &:not(:last-child) {
    margin-block-end: calc(var(--gap-block) * 3);

    &::after {
      content: '';
      display: block;
      margin-block-start: calc(var(--gap-block) * 3);
      margin-inline: auto;
      height: var(--border-width);
      width: calc(var(--gap-inline) * 2.5);
      background-color: var(--color-month);
      rotate: -45deg;
    }
  }
  /* &:nth-child(2n - 1 of &)  {
    &::after {
      rotate: 45deg;
    }
  } */
}

.m-set__header {
  margin-block-end: calc(var(--gap-block));
  font-size: var(--font-size-medium);
  line-height: var(--line-height--dense-2);

  > strong {
    display: block;
    margin-block-end: .2rem;
    line-height: var(--line-height-heading);
    color: var(--color-month);
    font-size: var(--font-size-h3);
    font-family: var(--font-family-serif);
    font-weight: var(--font-weight-serif-strong);
  }

  > h2 {
    font-size: var(--font-size-h1);
  }

  > p {
    margin-block-start: 0.4rem;
  }
}

.m-set__title {
  font-weight: var(--font-weight-serif-normal);

  & sup {
    font-size: 1.8rem;
    font-weight: var(--font-weight-serif-strong);
  }

  > strong {
    font-weight: var(--font-weight-serif-strong);
  }

  & a {
    text-decoration: none;
    > span {
      text-decoration: underline;
      text-decoration-color: var(--color-gray-300);
      text-decoration-thickness: 0.07ex;
      text-underline-offset: 0.22ex;
      transition-property: text-decoration-color;
      transition-duration: 200ms;
    }

    &:focus-visible,
    &:hover {
      > span {
        text-decoration-color: currentcolor;
      }
    }
  }
}

.m-set__occupation {
  display: inline-block;

  &::before {
    content: '';
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    margin-block-end: -1.1rem;
    margin-inline-start: -1.6rem;
    background-color: var(--color-month);
  }

  > h3 {
    margin-block-end: calc(var(--gap-block) * 0.25);
    font-size: var(--font-size-xx-small);
    font-family: var(--font-family-sans);
    font-weight: var(--font-weight-sans-normal);
    color: var(--color-month);
  }

  > strong {
    font-family: var(--font-family-serif);
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-serif-strong);
  }
}

@keyframes mEventHeaderSlideIn {
  from {
    translate: -100%;
  }
  to {
    translate: 0;
  }
}

.m-event-header {
  display: grid;
  grid-template-columns: minmax(2.1rem, 1fr) min(calc(var(--max-width-text) + 1.6rem * 2), calc(100% - 2.4rem * 2)) minmax(2.4rem, 1fr);
  color: var(--color-month);

  > * {
    grid-column-start: 2;
  }
}

.m-event-header__form {
  grid-column-start: 1;
  grid-row-end: span 3;
  background-color: var(--color-month);

  @media (prefers-reduced-motion: no-preference) {
    animation-name: mEventHeaderSlideIn;
    animation-duration: 800ms;
  }
}

.m-event-header__facts {
  display: grid;
  font-size: var(--font-size-h1);
  border-block-end:var(--border-width) solid var(--color-month);

  @media (min-width: 480px) {
    grid-template-columns: 9.4ch repeat(2, auto);
  }
}

.m-event-header__date,
.m-event-header__location,
.m-event-header__prices {
  border-inline-start: var(--border-width) solid var(--color-month);
  padding-inline: calc(1.6rem - var(--border-width));
  font-size: var(--font-size-x-small);
}

.m-event-header__location,
.m-event-header__prices {
  > strong {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-sans-normal);
  }

  @media (min-width: 480px) {
    padding-block-start: 1.3rem;
  }
}

.m-event-header__date {
  padding-block-end: 0.4rem;
  font-size: var(--font-size-h1);
  line-height: var(--line-height-heading);
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-serif-strong);

  .m-event-header:where(.-cancelled, .-postponed) & {
    text-decoration-style: solid;
    text-decoration-line: line-through;
  }
}

.m-event-header__location {
  padding-block-end: 0.8rem;
  line-height: var(--line-height-dense);

  > p + p {
    margin-block-start: 0;
  }
}

.m-event-header__text-buttons {
  display: flex;
  column-gap: 1.6rem;
}

.m-event-header__prices {
  padding-block-end: 0.8rem;
  line-height: var(--line-height-dense);

}

.m-event-header__title {
  order: 2;
  padding-block-start: .8rem;
  padding-inline: calc(1.6rem - var(--border-width)) 1.6rem;
  border-inline-start: var(--border-width) solid var(--color-month);
  font-weight: var(--font-weight-serif-normal);
  font-size: var(--font-size-h0);

  /* Veranstaltung abgesagt oder verschoben */
  > em {
    display: block;
    margin-block-start: 1.2rem;
    margin-block-end: 0.8rem;
    font-family: var(--font-family-sans);
    font-weight: var(--font-weight-sans-strong);
  }

  > strong {
    display: block;
  }

  & a {
    text-decoration-color: currentcolor;
    text-underline-offset: 0.2ex;
    text-decoration-thickness: 0.1ex;
    transition-property: text-decoration-thickness;

    &:focus-visible,
    &:hover {
      text-decoration-thickness: 0.15ex;
    }
  }
}

.m-event-header__description {
  order: 3;
  padding-block-start: calc(var(--gap-block) * 0.5);
  padding-inline: calc(1.6rem - var(--border-width)) 1.6rem;
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  border-inline-start: var(--border-width) solid var(--color-month);
}

.a-label {
  > span {
    display: block;
    font-size: var(--font-size-small);
    line-height: var(--line-height-dense);
    letter-spacing: var(--letter-spacing-small);
    font-weight: var(--font-weight-sans-normal);

    > abbr {
      font-weight: var(--font-weight-sans-strong);
      color: var(--color-gray);
      cursor: help;
    }
  }

  &.-checkbox {
    > span {
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      column-gap: 0.8rem;
    }

    .a-checkbox {
      flex-shrink: 0;
    }
  }

  &.-newsletter {
    position: relative;
  }
}

.a-label__text {
  .a-label.-newsletter & {
    position: absolute;
    left: 0;
    top: -1.4rem;
    margin-block-end: -4rem;
    display: inline-block;
    box-sizing: border-box;
    margin-inline: .5rem;
    padding: 0.2rem 0.4rem;
    color: var(--color-gray-200);
    translate: 0 2.1rem;
    transform-origin: left;
    background-image: linear-gradient(transparent 62%, transparent 62%);
    transition-property: background-image, color, translate, scale;
    transition-duration: 200ms;
  }
  .a-label.-newsletter.-not-empty &,
  .a-label.-newsletter:focus-within & {
    translate: none;
    scale: calc(1.5 / 1.7);
    color: var(--color-gray-200);
    background-image: linear-gradient(var(--color-positive) 62%, var(--color-gray-800) 62%);
  }
}

.a-label__error:not(:empty) {
  margin-block-start: .8rem;
  color: var(--color-danger);
}

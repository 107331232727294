* {
  margin: 0;
  padding: 0;
}

html {
  /* 1rem equals 10px, when 16px is default font-size */
  line-height: 1.5;
  font-family: system-ui, sans-serif;
  font-size: 62.5%;
  -webkit-text-size-adjust: none;
  color-scheme: dark light;
}
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  min-block-size: 100%;
  min-block-size: 100dvb;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

dialog {
  margin: auto;
}

a {
  color: inherit;
  text-underline-thickness: 0.2ex;
  text-underline-offset: 0.2ex;
}

nav ul {
  list-style: none;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
}

img, picture, svg {
  max-inline-size: 100%;
  block-size: auto;
}

iframe {
  border: 0;
}

fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

label {
  display: block;
}

input,
textarea,
button {
  margin: 0;
  border-radius: 0;
  padding: 0;
  font: inherit;
  line-height: inherit;
  color: inherit;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
}

input[type="date"],
input[type="time"] {
  appearance: none;

  &::-webkit-date-and-time-value {
    text-align: inherit;
  }
  /* ::-webkit-calendar-picker-indicator {
    display: none;
  } */
}

textarea {
  resize: vertical;
  resize: block;
}

button {
  text-align: start;
}

button, summary {
  border: 0;
  cursor: pointer;
}

hr {
  border: none;
  border-block-start: 1px solid;
  color: inherit;
  block-size: 0;
  overflow: visible;
}

summary {
  display: block;
}

abbr {
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-thickness: 0.2ex;
  text-underline-offset: 0.2ex;
}

table {
  border-collapse: collapse;
}
table :where(th, td) {
  vertical-align: top;
}
table th {
  text-align: start;
}

:disabled {
  cursor: not-allowed;
}

:focus-visible {
  outline: 2px solid Highlight;
  outline-offset: 2px;
}

[hidden]:not([hidden="until-found"]) {
  display: none !important;
}

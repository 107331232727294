.a-caption {
  margin-block-start: .8rem;
  padding-inline-start: calc(var(--gap-inline) + 1.6rem);
  line-height: var(--line-height-dense-2);
  font-size: var(--font-size-small);
  color: var(--color-month);

  &::before {
    content: '';
    display: inline-block;
    vertical-align: top;
    height: 1em;
    margin-inline-start: -1.9rem;
    margin-inline-end: .7rem;
    aspect-ratio: 15 / 20;
    mask-image: url('/assets/images/icon-arrow-round-north--400.svg');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: auto 1em;
    background-color: currentcolor;
    translate: -0.1em 0.12em;
  }
}

.m-cite {
  --padding-inline-big: calc(var(--gap-inline) * 5);
  box-sizing: border-box;
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  width: 100%;
  margin-inline: auto;
  padding-inline-end: var(--gap-inline);
  padding-inline-start: var(--padding-inline-big);

  & + & {
    padding-inline-start: var(--gap-inline);
    padding-inline-end: var(--padding-inline-big);
  }

  * + & {
    margin-block-start: calc(var(--gap-block) * 2);
  }

  @media not (min-width: 480px) {
    --padding-inline-big: calc(var(--gap-inline) * 2.5);
  }
}

.m-cite__original,
.m-cite__translated {
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);

  > p:first-of-type {
    text-indent: -0.6ch;
  }
  > p:first-of-type::before {
    content: '“';
  }
  > p:last-of-type::after {
    content: '”';
  }

  > p + p {
    margin-block-start: calc(var(--line-height) * 1em * 0.25);
  }

  &[lang="de"] {
    > p:first-of-type::before {
      content: '„';
    }
    > p:last-of-type::after {
      content: '“';
    }
  }
}

.m-cite__translated {
  margin-block-start: var(--gap-block);
  color: var(--color-gray-600);
}

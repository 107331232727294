.o-person__container {
  margin-block-start: var(--gap-block);

  > h2 {
    max-width: calc(var(--max-width-text));
    margin-block-start: calc(var(--gap-block) * 2);
    margin-inline: auto;
    padding-inline: var(--gap-block);
  }
}

.o-person__header {
  box-sizing: border-box;
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  width: 100%;
  margin-inline: auto;
  padding-inline: var(--gap-inline);
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);

  & h1 + p {
    margin-block-start: 0.4rem;
  }
  & h1 + .a-button-text {
    margin-block-start: 0.4rem;
  }
  & p + .a-button-text {
    margin-block-start: 0.2rem;
  }
}

.o-person__works {
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  margin-inline: auto;
  margin-block-start: calc(var(--gap-block) * 1);
  padding-inline: .8rem;
}

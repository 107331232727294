.m-news-item {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin-inline: var(--gap-inline);
  max-width: calc(var(--max-width-text) + 1.6rem * 2);
  text-decoration: none;

  &.-month-jan {
    --color-month: var(--color-jan);
  }
  &.-month-feb {
    --color-month: var(--color-feb);
  }
  &.-month-mar {
    --color-month: var(--color-mar);
  }
  &.-month-apr {
    --color-month: var(--color-apr);
  }
  &.-month-may {
    --color-month: var(--color-may);
  }
  &.-month-jun {
    --color-month: var(--color-jun);
  }
  &.-month-jul {
    --color-month: var(--color-jul);
  }
  &.-month-aug {
    --color-month: var(--color-aug);
  }
  &.-month-sep {
    --color-month: var(--color-sep);
  }
  &.-month-oct {
    --color-month: var(--color-oct);
  }
  &.-month-nov {
    --color-month: var(--color-nov);
  }
  &.-month-dec {
    --color-month: var(--color-dec);
  }

  @media (min-width: 414px) {
    margin-inline-start: min(12rem, 11vw);
  }
  @media (min-width: 480px) {
    margin-inline-end: calc(var(--gap-inline) * 2);
  }
  @media (min-width: calc(728px + 56px * 2)) {
    margin-inline: auto;
  }
}

.m-news-item__header {
  --size-symbol: calc(var(--font-size-h3) * var(--line-height-dense) * 2 + var(--border-width));
  margin-inline-start: calc(var(--size-symbol) * -1);
  display: grid;
  height: 3.4rem;
  grid-template-columns: calc(var(--font-size-h3) * var(--line-height-dense) * 2 + var(--border-width)) auto;
}

.m-news-item__date {
  position: relative;
  display: block;
  height: calc(3.4rem - var(--border-width));
  padding-inline: calc(1.6rem - var(--border-width));
  padding-block-start: .1rem;
  padding-block-end: .1rem;
  line-height: var(--line-height-dense);
  font-size: var(--font-size-h3);
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-serif-normal);
  color: var(--color-month);
  border-inline-start: var(--border-width) solid var(--color-month);
  border-block-end: var(--border-width) solid var(--color-month);
  letter-spacing: 0.04em;

  &::first-line {
    letter-spacing: -0.04em;
  }
}

.m-news-item__symbol {
  --size: calc(var(--gap-inline) * 2.5);
  display: block;
  block-size: var(--size);
  aspect-ratio: 1;
  justify-self: end;

  .m-news-item.-news & {
    background-image: linear-gradient(135deg, var(--color-month) 50%, transparent 50%);
  }
  .m-news-item.-event & {
    background-color: var(--color-month);
  }
}

.m-news-item__container {
  display: grid;
  border-inline-start: var(--border-width) solid var(--color-month);
  transition-property: color;
  transition-duration: 200ms;

  @media (min-width: 560px) {
    .m-news-item.-teaser-image & {
      grid-template-columns: 1fr 1fr;
      gap: calc(var(--gap-inline) * 2);
    }
  }
}

.m-news-item__text {
  /* padding-block-end: .2rem; */
  line-height: var(--line-height-dense-2);
  padding-block-end: calc(var(--gap-block) * 1.5);
  padding-inline-start: 1.6rem;

  > .a-button-text {
    display: inline-block;
    line-height: var(--line-height-dense);
    font-size: var(--font-size-x-small);
    transition-property: color;
    transition-duration: 200ms;
  }

  .m-news-item:where(:focus-visible, :hover) & {
    > .a-button-text {
      color: var(--color-month);
    }
  }

  @media (min-width: 414px) {
    padding-inline: 1.6rem;
  }
}

.m-news-item__title {
  margin-block-start: 1rem;
  margin-block-end: .8rem;
  font-family: var(--font-family-serif);
  line-height: var(--line-height-dense);
  font-size: var(--font-size-h2);
}

.m-news-item__teaser-text {
  margin-block-end: .4rem;
}

.m-news-item__figure {
  order: -1;

  > picture {
    > img {
      width: 100%;
      background-color: var(--color-gray-100);
    }
  }

  > .a-caption {
    padding-inline-start: 1.6rem;
    color: inherit;
    text-align: end;
  }

  @media (min-width: 560px) {
    order: 1;
  }
}

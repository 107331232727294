.m-figure {
  box-sizing: border-box;
  margin-inline: auto;
  max-width: calc(var(--max-width-text) + var(--gap-inline) * 2);
  padding-inline: .8rem;
  width: 100%;

  > img {
    background-color: var(--color-gray-100);
  }

  * + & {
    margin-block-start: calc(var(--gap-block) * 2);
  }
}

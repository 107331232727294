.a-button-text {
  display: inline-block;
  line-height: var(--line-height-dense);
  text-decoration: none;

  > span {
    display: inline-block;
    position: relative;
    vertical-align: text-bottom;
    margin-inline-start: -.05em;
    block-size: 1em;
    inline-size: 1em;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      inset-block-start: -.45em;
      display: inline-block;
      mask-image: url('/assets/images/icon-arrow-east--400__stem.svg');
      mask-position: left center;
      mask-repeat: no-repeat;
      mask-size: auto 1em;
      background-color: currentcolor;
    }

    &::after {
      mask-image: url('/assets/images/icon-arrow-east--400__tip.svg');
      transition-property: translate;
      transition-duration: 150ms;
    }
  }
}
.a-button-text:where(:focus-visible, :hover),
a:where(:focus-visible, :hover) .a-button-text {
  > span {
    &::after {
      translate: .1em 0;
    }
  }
}
body.-non-apple .a-button-text.-apple-only {
  display: none;
}
body.-apple .a-button-text.-non-apple-only {
  display: none;
}

.m-news-header {
  display: block;
  max-width: calc(var(--max-width-text) + 1.6rem * 2);
  margin-inline: var(--gap-inline);

  @media (min-width: 414px) {
    margin-inline-start: min(12rem, 11vw);
  }
  @media (min-width: 480px) {
    margin-inline-end: calc(var(--gap-inline) * 2);
  }
  @media (min-width: 896px) {
    margin-inline: auto;
  }
}

.m-news-header__date {
  display: block;
  padding-block-start: .1rem;
  padding-block-end: .4rem;
  padding-inline: calc(1.6rem - var(--border-width));
  line-height: var(--line-height-dense);
  font-size: var(--font-size-h3);
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-serif-strong);
  color: var(--color-month);
  border-inline-start: var(--border-width) solid var(--color-month);
  border-block-end: var(--border-width) solid var(--color-month);
}

.m-news-header__title {
  padding-block-start: .8rem;
  padding-inline: calc(1.6rem - var(--border-width));
  font-size: var(--font-size-h0);
  border-inline-start: var(--border-width) solid var(--color-month);
}

.m-news-header__teaser-text {
  margin-block-end: 4.8rem;
  padding-block-start: 1.6rem;
  padding-inline: calc(1.6rem - var(--border-width));
  font-size: var(--font-size-large);
  line-height: var(--line-height-dense-2);
  font-weight: var(--font-weight-sans-normal);
  border-inline-start: var(--border-width) solid var(--color-month);
}
